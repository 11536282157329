import React, { useState } from 'react';
import {
  Card, Button, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import routerImg from '../images/xiaomi_ax3200.png';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectSignUp, setRouter } from '../features/signup/signupSlice';
import { Router } from '../models/routers';
import ScrollStack from './ScrollStack';

const RouterCompatibilityPopover = (
  <Popover
    style={{
      borderTopLeftRadius: '3em',
      borderTopRightRadius: '3em',
    }}
    id="router-compatibility-popover"
    className="shadow-sm"
  >
    <Popover.Header
      className="bg-primary text-white"
      as="h4"
    >
      Router compatibility
    </Popover.Header>
    <Popover.Body>
      <h6 className="mb-1">Connection port</h6>
      <p className="fw-light" style={{ fontSize: '0.9em' }}>
        Your router requires an
        {' '}
        <strong>Ethernet WAN/Internet Port</strong>
        .
      </p>
      <p className="fw-light" style={{ fontSize: '0.9em' }}>
        Some routers will have a WAN port
        {' '}
        <em>and</em>
        {' '}
        a DSL port - we cannot guarantee compatibility of these routers and do
        not recommend using them on our network.
      </p>
      <h6 className="mb-1">Connection type</h6>
      <p className="fw-light" style={{ fontSize: '0.9em' }}>
        Your router must support
        {' '}
        <strong>PPPoE</strong>
        {' '}
        and
        {' '}
        <strong>DHCP/Dynamic/Automatic IP connections</strong>
        .
      </p>
      <h6 className="mb-1">Brand</h6>
      <p className="fw-light" style={{ fontSize: '0.9em' }}>
        Telstra or Belong branded routers are unfortunately not compatible with
        our network.
      </p>
      <hr className="my-2" />
      <p className="mb-0 fw-light" style={{ fontSize: '0.9em' }}>
        Read more:
        {' '}
        <a
          target="_blank"
          href="https://kb.pineapple.net.au/help/is-my-router-compatible"
          rel="noreferrer"
        >
          Is my router compatible?
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

interface RoutersProps {
  routers: Record<string, Router>;
  className?: string,
}

const Routers = ({ className = '', routers }: RoutersProps) => {
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector(selectSignUp);

  const routersState: Record<string, boolean> = Object.keys(routers)
    .reduce((acc, id) => ({
      ...acc,
      [id]: signUpState.router.id === id,
    }), {});

  const [
    buttonState,
    setButtonState,
  ] = useState<Record<string, boolean>>(routersState);
  const setChange = (router: string) => {
    Object.keys(buttonState).forEach((routerName) => {
      buttonState[routerName] = false;
    });
    buttonState[router] = !buttonState[router];
    setButtonState({
      ...buttonState,
    });
    dispatch(setRouter(routers[router]));
  };

  return (
    <ScrollStack
      mostPopular={false}
      className={`
          hide-scrollbar
          p-2
          overflow-scroll
          d-flex
          align-items-stretch ${className}`}
      gap={2}
      direction="horizontal"
    >
      {Object.keys(routers).map((id) => (
        <Card
          key={id}
          className="shadow pineapple-card"
          border="gray"
        >
          <Card.Body className="d-flex flex-column">
            <Card.Title
              className="
                  text-center
                  text-primary
                  border-bottom
                  border-primary
                  fs-3
                  pb-2
                  mb-0"
            >
              {routers[id].name}
            </Card.Title>
            <Card.Text className="py-2">
              { routers[id].description1
                ? `${routers[id].description1}\n` : null }
            </Card.Text>
            { routers[id]?.cost === '0'
                  && (
                  <Card.Text
                    className="py-2
                    text-center
                    d-flex
                    h-100
                    justify-content-center
                    align-items-end"
                  >
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="top"
                      overlay={RouterCompatibilityPopover}
                    >
                      <Button
                        className="fw-light px-2"
                        style={{
                          borderColor: "#f58238",
                          background: "#f5821f",
                          borderWidth: "2px",
                          color: "#fff",
                          fontSize: "18px",
                          marginBottom: "40px",
                        }}
                        variant="outline-primary"
                        size="sm"
                        id="button_check_router"
                      >
                        Check router compatibility
                      </Button>
                    </OverlayTrigger>
                  </Card.Text>
                  )}
            { routers[id]?.cost !== '0'
              ? (
                <div
                  className="d-flex justify-content-center"
                >
                  <div className="position-relative d-inline-block">
                    <Card.Img
                      style={{ width: 'auto', height: '200px' }}
                      src={routerImg}
                    />
                  </div>
                </div>
              ) : null }
            <div className="w-100 align-self-end mt-auto">
              <Card.Text className="text-center">
                <span className="
                  display-1
                  text-primary
                  text-center
                  lh-1
                  d-block"
                >
                  $
                  {routers[id]?.cost || 0}
                </span>
                <span className="fs-6">
                  {routers[id]?.description2}
                </span>
              </Card.Text>
              <Button
                className="w-100"
                size="lg"
                onClick={() => setChange(id)}
                variant={buttonState[id] ? 'secondary' : 'primary'}
                id={`select_router_${id}`}
              >
                { buttonState[id] ? 'Selected' : 'Select' }
              </Button>
            </div>
          </Card.Body>
        </Card>
      ))}
    </ScrollStack>
  );
};

export default Routers;
