import React, { PropsWithChildren } from 'react';

interface PineappleAlertProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  classOverride?: string
}

const PineappleAlert = (
  {
    children, className, classOverride, ...props
  }: PropsWithChildren<PineappleAlertProps>,
) => (
  <div
    className={classOverride || `bg-secondary my-3 p-2 text-center rounded-pill ${className}`}
    {...props}
  >
    { children }
  </div>
);

export default PineappleAlert;
