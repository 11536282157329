import Rollbar from 'rollbar';

const RollbarConfig = {
  accessToken: '8dfaffc6381f4009bfa57377ab0e9950',
  environment: process.env.REACT_APP_ENV || 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const RollbarClient = new Rollbar(RollbarConfig);

export { RollbarClient, RollbarConfig };
