import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import PineappleModal from './PineappleModal';
import { Coupon, CouponType } from '../features/coupon/couponAPI';
import {
  Discount,
  discountWording,
  discountFromCoupon,
} from '../features/discount/discountSlice';

interface DiscountModalProps {
  currentDiscount: Discount;
  proposedCoupon: Coupon;
  onSubmit: (accepted: boolean) => void
}

const DiscountModal = ({
  currentDiscount,
  proposedCoupon,
  onSubmit,
}: DiscountModalProps) => {
  const currentDiscountInWords = discountWording(currentDiscount);
  const proposedDiscountInWords = discountWording(
    discountFromCoupon(proposedCoupon),
  );

  const [proposalAccepted, setProposalAccepted] = useState<boolean>(false);

  return (
    <PineappleModal
      handleClose={() => { onSubmit(proposalAccepted); }}
      show
      footer={(
        <Modal.Footer className="d-flex justify-content-center pt-0">
          <Button
            id="check_address_modal_success"
            variant="primary"
            size="lg"
            onClick={() => { onSubmit(proposalAccepted); }}
          >
            <span>Continue</span>
          </Button>
        </Modal.Footer>
        )}
    >
      <h1 className="text-center text-primary fw-bold w-100">
        Confirm Offer
      </h1>
      <p className="lead text-center mb-3">
        You have entered a code that changes the offer on your order.
        Please choose the offer you wish to apply to your order.
      </p>
      <Stack className="mb-3" direction="vertical" gap={3}>
        <div className="d-grid">
          <h5 className="text-center">{ currentDiscount?.title || "Current Offer" }</h5>
          <Button
            className={proposalAccepted ? 'muted' : 'selected'}
            size="lg"
            variant="secondary"
            onClick={() => { setProposalAccepted(false); }}
          >
            { currentDiscountInWords }
          </Button>
        </div>
        <div className="d-grid">
          <h5 className="text-center">
            { proposedCoupon.coupon_type === (CouponType.ReferralCode || CouponType.SalesCommission) ? 'Referral' : 'Promo' }
            {' '}
            Code Offer
          </h5>
          <Button
            className={proposalAccepted ? 'selected' : 'muted'}
            size="lg"
            variant="secondary"
            onClick={() => { setProposalAccepted(true); }}
          >
            { proposedDiscountInWords }

          </Button>
        </div>
      </Stack>
    </PineappleModal>
  );
};

export default DiscountModal;
