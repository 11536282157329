import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchRouters } from './routersAPI';
import { Router } from '../../models/routers';

export interface RoutersState {
  value: Array<Router>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: RoutersState = {
  value: [],
  status: 'idle',
};

export const fetchRoutersAsync = createAsyncThunk(
  'routers/fetchRouters',
  async () => {
    const response = await fetchRouters();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const routersSlice = createSlice({
  name: 'routers',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoutersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRoutersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchRoutersAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRouters = (state: RootState) => state.routers;
export default routersSlice.reducer;
