import React, { useState } from 'react';
import {
  Col, Row, Stack, Button, Container,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm, Resolver } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setContacts, selectSignUp } from '../features/signup/signupSlice';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const ContactInput = ({ register, formState: { errors }, getValues }: any) => {
  const signUpState = useAppSelector(selectSignUp);

  return (
    <Container>
      <div
        style={{ borderTop: '2px solid' }}
        className="
          align-self-end
          py-2
          border-primary"
      >
        <h4>Primary Contact</h4>
        <Form>
          <Row className="mb-2">
            <Form.Group controlId="firstName">
              <Form.Label>First Name *</Form.Label>
              <Form.Control
                className="input-with-radius"
                defaultValue={signUpState.arrivy.customer_first_name}
                {...register('firstName', {
                  required: 'First name is required',
                  setValueAs: (value: any) => (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()),
                  validate: (value: string) => {
                    if (value.length < 2) {
                      return 'First name should contain at least two letters';
                    } if (value.length <= 26) {
                      return true;
                    }
                    return 'First name should not be longer than 26 letters';
                  },
                  pattern: {
                    value: /^([a-zA-Z']+\s)*[a-zA-Z']+$/,
                    message: 'invalid first name',
                  },
                })}
              />
              {errors.firstName && (
                <Form.Text className="text-info">
                  {errors.firstName.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group controlId="lastName">
              <Form.Label>Last Name *</Form.Label>
              <Form.Control
                className="input-with-radius"
                defaultValue={signUpState.arrivy.customer_last_name}
                {...register('lastName', {
                  required: 'Last name is required',
                  setValueAs: (value: any) => (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()),
                  validate: (value: string) => {
                    if (value.length < 2) {
                      return 'Last name should contain at least two letters';
                    } if (value.length <= 26) {
                      return true;
                    }
                    return 'Last name should not be longer than 26 letters';
                  },
                  pattern: {
                    value: /^([a-zA-Z']+\s)*[a-zA-Z']+$/,
                    message: 'Invalid last name',
                  },

                })}
              />
              {errors.lastName && (
                <Form.Text className="text-info">
                  {errors.lastName.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group controlId="phone">
              <Form.Label>Mobile *</Form.Label>
              <Form.Control
                className="input-with-radius"
                type="phonepad"
                defaultValue={signUpState.arrivy.customer_mobile_number}
                {...register('phone', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^(0|\+61)4[0-9]{8}$/,
                    message: 'Please enter your mobile number as 04123456789 or +61412345678',
                  },
                })}
              />
              {errors.phone && (
                <Form.Text className="text-info">
                  {errors.phone.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                className="input-with-radius"
                type="email"
                defaultValue={signUpState.arrivy.customer_email}
                {...register('email', {
                  required: 'Invalid email',
                  validate: (value: string) => {
                    if (value.length <= 320) {
                      return true;
                    }
                    return 'Email length is limited to 320 symbols';
                  },
                  pattern: {
                    value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                    message: 'Please enter correct email address e.g. john.smith@example.com',
                  },
                })}
              />
              {errors.email && (
              <Form.Text className="text-info">
                {errors.email.message}
              </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="email">
              <Form.Label>Confirm Email *</Form.Label>
              <Form.Control
                className="input-with-radius"
                type="email"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                {...register('emailConfirm', {
                  required: 'Email confirmation is required',
                  validate: (value: string) => {
                    if (value === getValues('email')) {
                      return true;
                    }
                    return 'Email confirmation must match email';
                  },
                })}
              />
              {errors.emailConfirm && (
              <Form.Text className="text-info">
                {errors.emailConfirm.message}
              </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="how_did_you_hear_about_us">
              <Form.Label>How did you hear about us?</Form.Label>
              <Form.Select
                defaultValue=""
                className="input-with-radius"
                {...register('howDidYouHearAboutUs', {
                  required: 'Please let us know how you heard about us',
                })}
              >
                <option
                  disabled
                  value="" // Not truthy - fails form validation when selected
                >
                  -- Select An Option --
                </option>
                <option value="Search Engine">Search Engine</option>
                <option value="Google Ads">Google Ads</option>
                <option value="Social Media">Social Media</option>
                <option value="Digital Advertising">Digital Advertising</option>
                <option value="Word Of Mouth">Word Of Mouth</option>
                <option value="Building Manager">Building Manager</option>
                <option value="In Building Marketing Material">
                  In Building Marketing Material
                </option>
                <option value="Referral">Referral</option>
                <option value="Sponsorship">Sponsorship</option>
                <option value="Other">Other</option>
              </Form.Select>
              {errors.howDidYouHearAboutUs && (
              <Form.Text className="text-info">
                {errors.howDidYouHearAboutUs.message}
              </Form.Text>
              )}
            </Form.Group>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default ContactInput;
