import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { ulid } from 'ulid';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import enAU from 'date-fns/locale/en-AU/index';
import {
  Container, Col, Row, Spinner, Stack, Button, Alert,
} from 'react-bootstrap';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  WantedBooking,
  initialState,
  fetchBookingsAsync,
  selectWantedBookingId, selectBookings, selectBookingState, setWantedBookingId,
} from '../features/bookings/bookingsSlice';
import './react-datepicker.css';
import { selectAddress } from '../features/address/addressSlice';
import {
  filterDuplicates,
  pineAppleTime,
  pineAppleDay,
} from '../lib/utils';
import { fetchBooking } from '../features/bookings/bookingsAPI';
import {
  createReservation,
  releaseReservation,
  selectReservationState,
} from '../features/reservations/reservationsSlice';
import { PickerBooking } from '../models/picker_booking';
import Countdown from './Cowntdown';
import signupSlice, { selectSignUp } from '../features/signup/signupSlice';

registerLocale('en-AU', enAU);

export interface Errors {
  error: boolean
  errText: string
}

export interface PickerProps {
  onSubmit: any
  previousStep: any
}

const nextMonth = (date = new Date()) => new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  1,
);

const prevMonth = (date = new Date()) => new Date(
  date.getFullYear(),
  date.getMonth() - 1,
  1,
);

const makeDateString = (date: Date): string => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

const Picker = ({ onSubmit, previousStep }: PickerProps) => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const bookings = useAppSelector(selectBookings);
  const bookingsState = useAppSelector(selectBookingState);
  const wantedBooking = useAppSelector(selectWantedBookingId);
  const signUp = useAppSelector(selectSignUp);
  const {
    value: reservation,
    status: reservationStatus,
  } = useAppSelector(selectReservationState);

  const ref = useRef<null | HTMLElement>(null);

  const [alreadyTurnedTheMonth, setAlreadyTurnedThemonth] = useState(false);
  const [selectedDate, setSelectedDate] = useState<boolean | Date>(false);
  const [openDate, setOpenDate] = useState(new Date());
  const [expired, setExpired] = useState(false);

  const getMoreBookings = useCallback((from: Date) => {
    if (bookings.length === 0) {
      dispatch(fetchBookingsAsync({ state: addr.state, date: from }));
      return;
    }

    // Assume the bookings we get from Arrivy are time ordered
    const lastBookingDate = new Date(bookings[bookings.length - 1]
      .start_datetime_original_iso_str);
    // We show 2 months at a time so we want to compare with 1 month ahead.
    const oneMonthAhead = new Date(from.setMonth(from.getMonth() + 1));

    // Check that we've got more bookings than what is visible on the calendar.
    if (lastBookingDate < oneMonthAhead) {
      dispatch(fetchBookingsAsync({ state: addr.state, date: from }));
    }
  }, [bookings]);

  // Get initial bookings
  useEffect(() => {
    setOpenDate(new Date());
    getMoreBookings(openDate);
  }, []);

  const EVENTS: Array<PickerBooking> = filterDuplicates(bookings);

  const [getIncreaseMonth, setIncreaseMonth] = useState<any>(() => () => console.log("welcome to Pineapple!"));
  const monthMap = new Set<number>();
  EVENTS.forEach((event) => {
    monthMap.add(event.start.getMonth());
  });
  useEffect(() => {
    const currentMonth = (new Date()).getMonth();

    if (EVENTS.length > 0 && !monthMap.has(currentMonth) && !alreadyTurnedTheMonth) {
      getMoreBookings(nextMonth(new Date()));
      setOpenDate(nextMonth(new Date()));
      getIncreaseMonth();
      // without that will go in the infinite loop of month scrolling
      setAlreadyTurnedThemonth(true);
    }
  }, [bookingsState.value]);

  // Page through to the next month if there are no installation slots in the
  // current month after fetching the initial set of available times.
  useEffect(() => {
    if (bookings.length === 0 && bookingsState !== initialState) {
      setOpenDate(nextMonth(openDate));
    }
  }, [bookings]);

  const [availableTime, setAvailableTime] = useState([] as Array<PickerBooking>);

  const [errors, setErrors] = useState<Array<string>>([]);
  const [timeLoading, setTimeLoading] = useState(false);

  useEffect(() => {
    setErrors([
      ...errors.filter((elem) => elem !== "We're sorry, but the date and time you've selected are no longer available. Please choose another date and time."),
      "We're sorry, but the date and time you've selected are no longer available. Please choose another date and time."]);
  }, [expired]);

  const onChange = async (date: Date) => {
    setErrors([]);
    setTimeLoading(true);
    setSelectedDate(date);
    dispatch(setWantedBookingId({} as WantedBooking));

    await fetchBooking(addr.state, date).then((resp) => {
      const events: any = filterDuplicates(resp.data.booking_slots);
      const filteredEvents: Array<PickerBooking> = events.filter((event: PickerBooking) => event.start.getDay() === events[0].start.getDay());
      setAvailableTime(filteredEvents);
    }).catch((error) => {
      console.log('ERROR loading bookings', error);
      setErrors([...errors, error]);
    });

    if (ref) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    setTimeLoading(false);
  };

  const submitHandle = () => {
    let errs: Array<string> = [];
    if (selectedDate === false) {
      errs = [...errs, 'Please select an installation date'];
    }
    if (!wantedBooking.event_id || wantedBooking.event_id === undefined) {
      errs = [...errs, 'Please select an installation time.'];
    }
    if (errs.length === 0) {
      onSubmit();
    }
    setErrors(errs);
  };

  useEffect(() => {
    setErrors([]);
  }, [availableTime, wantedBooking]);

  useEffect(() => {
    /* if (reservation != null) {
      // Progress to the next form step when we've secured a reservation.
      onSubmit();
    } */
    if (reservationStatus === 'failed') {
      // need to sue sets !?
      setErrors([
        ...errors.filter((elem) => elem !== "We're sorry, but the date and time you've selected are no longer available. Please choose another date and time."),
        "We're sorry, but the date and time you've selected are no longer available. Please choose another date and time."]);
    }
  }, [reservation, reservationStatus]);

  useEffect(() => {
    if (wantedBooking?.event_id) {
      dispatch(
        createReservation(
          {
            external_id: wantedBooking.event_id.toString(),
            address: addr.formatted_address,
            sid: signUp.arrivy.title,
          },
        ),
      );
      // clear up the error
      setErrors([]);
    }
  }, [wantedBooking]);

  if (bookingsState.status === 'loading') {
    return (
      <div className="center">
        <Spinner
          style={{ width: '6rem', height: '6rem' }}
          animation="border"
          variant="primary"
        />
        <h1 className="fw-bold text-primary text-center px-5">
          {' '}
          Loading available bookings
        </h1>
      </div>
    );
  }
  return (
    <Container fluid="xl">
      <Row className="mb-2 justify-content-center">
        <Col xs={12}>
          <h1 className="fw-bold text-primary text-center">
            Select your preferred installation date
          </h1>
          <p className="lead text-center text-center px-md-5">
            We utilise the DGtek Fibre Network, which operates independently from the NBN
            and other providers within your building. To establish a connection to the
            DGtek network for your apartment, a DGtek installer will be required to visit.
            The installation process usually spans approximately 45-60 minutes on the
            scheduled day.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col>
          <DatePicker
            monthsShown={2}
            onChange={onChange}
            selected={selectedDate}
            openToDate={openDate}
            inline
            locale="en-AU"
            calendarStartDay={1}
            includeTimes={EVENTS.map((e) => e.start)}
            includeDates={EVENTS.map((e) => e.start)}
            renderCustomHeader={({
              monthDate,
              date,
              decreaseMonth,
              increaseMonth,
            }: any) => {
              setIncreaseMonth(() => increaseMonth);
              return (
                <>
                  <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    aria-label="Next Month"
                    onClick={() => {
                      getMoreBookings(nextMonth(date));
                      setOpenDate(nextMonth(date));
                      increaseMonth();
                    }}
                  >
                    <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">{'>'}</span>
                  </button>
                  <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    aria-label="Previous Month"
                    onClick={() => {
                      getMoreBookings(prevMonth(date));
                      setOpenDate(prevMonth(date));
                      decreaseMonth();
                    }}
                  >
                    <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">{'<'}</span>
                  </button>
                  <div className="react-datepicker__current-month">
                    {format(monthDate, 'MMMM')}
                    {' '}
                    {monthDate.getFullYear()}
                  </div>
                </>
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className={availableTime.length === 0 ? 'mb-2 d-none' : 'mb-2'}>
            <Col>
              <h1 className="text-center fw-bold text-primary">Select your preferred time</h1>
              <p className="lead text-center text-center px-md-5">
                Please note that when selecting your preferred time, it
                represents a time window rather than a specific time. On the
                day of installation, you&apos;ll receive an SMS notification
                specifying when the DGtek installer is due to arrive within&nbsp;your&nbsp;chosen&nbsp;timeframe.
              </p>
            </Col>
          </Row>
          {timeLoading ? (
            <Row>
              <div className="time-spinner">
                <Spinner
                  style={{ width: '2rem', height: '2rem' }}
                  animation="border"
                  variant="primary"
                />
                <h4 className="text-primary text-center px-2">
                  {' '}
                  Loading times
                </h4>
              </div>
            </Row>
          ) : (
            <Row className={`mb-4 justify-content-center ${availableTime.length === 0 && 'd-none'}`}>
              <Col>
                <div className="text-center">
                  {availableTime.map((d) => (
                    <Button
                      key={d.event_id}
                      id={`installation_time_${d.event_id}`}
                      className={(wantedBooking.start === d.start.valueOf()) ? 'time-picker-selected m-1' : 'time-picker m-1'}
                      variant={(wantedBooking.start === d.start.valueOf()) ? 'primary' : 'outline-light'}
                      size="lg"
                      onClick={() => {
                        if (ref) {
                          ref?.current?.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                        setErrors([]);
                        dispatch(setWantedBookingId({
                          ...d,
                          start: d.start.getTime(),
                          end: d.end.getTime(),
                        }));
                        setOpenDate(d.start);
                      }}
                    >
                      Between
                      {' '}
                      {pineAppleTime(d.start, addr.state)}
                      {' '}
                      -
                      {' '}
                      {pineAppleTime(d.end, addr.state)}
                    </Button>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          md={8}
          xs="auto"
          className="text-success"
        >
          {wantedBooking?.event_id
            && (
              <h4 className="font-bold text-body text-center">
                You have selected
                {' '}
                {pineAppleDay(new Date(wantedBooking.start))}
                {' between '}
                {pineAppleTime(new Date(wantedBooking.start), addr.state)}
                {' '}
                -
                {' '}
                {pineAppleTime(new Date(wantedBooking.end), addr.state)}
              </h4>
            )}
          {errors.map((e) => (
            <Alert
              variant="danger"
              key={ulid()}
              className="my-1 w-100 text-center px-2 py-1 border-0"
            >
              {e}
            </Alert>
          ))}
          {reservation?.expires_at && (
          <Alert
            variant="orange"
            className="mx-auto d-inline-block mb-4 my-2 px-2 py-1 text-white bg-orange text-center"
          >
            Your preferred installation date and time will be held for another
            {' '}
            <strong>
              <Countdown
                callBack={setExpired}
                endTime={dayjs(reservation.expires_at)}
              />
            </strong>
            {'. '}
            Ensure you have your payment details ready to complete your order within this timeframe to secure your spot.
          </Alert>
          )}
        </Col>
      </Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"

      >
        <Button
          id="installation_go_back"
          onClick={previousStep}
          size="lg"
          variant="outline-light"
        >
          <span
            ref={ref}
            className="text-body px-2"
          >
            Go back

          </span>
        </Button>
        <Button
          id="installation_continue"
          onClick={submitHandle}
          disabled={errors.length > 0}
          size="lg"
          variant="primary"

        >
          <span className="px-2">Continue</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default Picker;
