import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Address } from '@pineapple/address-search';
import { RootState } from '../../app/store';

export const emptyAddress = {} as Address;

export interface AddressState {
  value: Address;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AddressState = {
  value: emptyAddress,
  status: 'idle',
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAddressFromUrl: (state, action: PayloadAction<Address>) => {
      state.value = action.payload;
    },
    setAddress: (state, action: PayloadAction<Address>) => {
      state.value = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {},
});

export const { setAddress, setAddressFromUrl } = addressSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAddress = (state: RootState) => state.address.value;
export const selectAddrStatus = (state: RootState) => state.address.status;
export default addressSlice.reducer;
