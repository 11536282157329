import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import './Toggle.css'; // Make sure to create this CSS file

export interface MostPopularPlansSwitchProps {
  options: string[]
  checked: boolean
  onChange: MouseEventHandler<HTMLDivElement>
}

/* eslint-disable */
const MostPopularPlansSwitch = ({
  options, onChange, checked,
}: MostPopularPlansSwitchProps) =>

(
  <div className="d-flex justify-content-center">
    <div className="toggle-container">
      <div className="slider-toggle" onClick={onChange}>
        <div className={`slider ${checked ? 'all-plans' : 'popular-plans'}`}>
          <div className={`toggle-thumb ${checked ? 'all-plans' : 'popular-plans'}`} />
        </div>
        <div className="toggle-label popular-plans-label">{options && options.length === 2 ? options[0] : "All Plans"}</div>
        <div className="toggle-label all-plans-label">{options && options.length === 2 ? options[1] : "Most Popular"}</div>
      </div>
    </div>
  </div>
);

export default MostPopularPlansSwitch;
