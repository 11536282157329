import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';

interface ScrollIndicatorProps {
    activeIndex?: number,
    length: number
}

const ScrollIndicator = ({ activeIndex, length }: ScrollIndicatorProps) => (
  <ul className="p-2 list-inline">
    {Array.from(Array(length), (_, i) => (
      <li
        key={i}
        style={{ opacity: activeIndex === i ? 1 : 0.5 }}
        className="list-inline-item"
        color="#6d6d6d"
      >
        <CircleFill />
      </li>
    ))}
  </ul>
);

export default ScrollIndicator;
