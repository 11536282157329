import type { Address } from "@pineapple/address-search";
import httpClient from "../../http/client";
import { backendURL } from "../../lib/config";

export const enum CouponType {
  CouponCode = 1,
  ReferralCode = 2,
  MoveInCoupon = 3,
  SalesCommission = 4,
}

export type Coupon = {
  coupon_id: string;
  coupon_type: CouponType;
  period: number;
  max_redemptions: number;
  period_unit: string;
  dollar_amount: number;
  percentage: string;
  plan_ids: Array<string>;
};

const disallowedCouponCodes = [
  "PINEAPPLENET250",
  "PINEAPPLENET350",
  "PINEAPPLENET500",
  "PINEAPPLENET1000",
  "PINEAPPLENET100MBPS",
  "PINEAPPLENET150MBPS",
  "PINEAPPLENET500MBPS",
  "PINEAPPLENET1000MBPS",
];

const localValidate = (value: string, address: Address): boolean => {
  // Special case the SUMMER20 code for Southbank residents only
  if (
    value === "SUMMER20"
    && (address.postcode !== "3006"
      || address.suburb.toLowerCase() !== "southbank")
  ) {
    return false;
  }

  // Block use of the special codes that are not allowed to be used by people.
  if (disallowedCouponCodes.includes(value)) {
    return false;
  }

  return true;
};

export const CheckCoupon = (coupon: string, planIds: Array<string>) => httpClient.post<Coupon>(
  new URL("/api/v1/pineapple/coupon", backendURL).toString(),
  {
    coupon_id: coupon,
    plan_ids: planIds,
  },
  {
    headers: {
      Accept: "application/json",
    },
  },
);

export const ValidateCoupon = async (
  value: string,
  address: Address,
  planId: string,
) => {
  if (localValidate(value, address) === false) return false;

  try {
    const res = await CheckCoupon(value, [planId]);
    return res.status === 202;
  } catch {
    return false;
  }
};

export const GetValidCoupon = async (
  value: string,
  address: Address,
  planId: string,
) => {
  if (localValidate(value, address) === false) return null;

  try {
    const res = await CheckCoupon(value, [planId]);
    return res.data;
  } catch {
    return null;
  }
};
