import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { RootState } from "../../app/store";
import { fetchCampaign } from "./campaignAPI";
import { CheckCoupon, CouponType } from "../coupon/couponAPI";

// {
//   'id': '0188ce62-6f8f-76ce-b551-41105b812446',
//   'name': 'Test',
//   'rsp': 'Pineapple',
//   'startDate': '2023-06-18T00:00:00+10:00',
//   'endDate': '2023-07-19T23:59:59+10:00',
//   'monthsFree': 1,
//   'terms': ''
// }
export interface Campaign {
  id: string;
  name: string;
  rsp: string;
  startDate: string;
  endDate: string;
  terms: string;
  moveIn: boolean;
  referral: boolean;
  couponCode?: string;
  monthsFree?: number;
}

export interface CampaignState {
  value: Campaign;
  status: "idle" | "loading" | "failed";
  moveIn: boolean;
  referral: boolean;
}

const initialState: CampaignState = {
  value: {} as Campaign,
  status: "idle",
  referral: false,
  moveIn: false,
};

export const moveInCampaign = {
  id: "MOVEIN",
  name: "Move In Campaign",
  rsp: "Pineapple",
  startDate: dayjs().toISOString(),
  endDate: dayjs().add(1, "year").toISOString(),
  terms:
    "One Month Free credit will be applied to your account on the 7th month after installation.",
  couponCode: "PROMOMIOMF",
  monthsFree: 1,
  referral: false,
  moveIn: true,
} as Campaign;

export const twoMonthsMoveInCampaign = {
  id: "MOVEIN2",
  name: "Move In Campaign",
  rsp: "Pineapple",
  startDate: dayjs().toISOString(),
  endDate: dayjs().add(1, "year").toISOString(),
  terms:
    "Two Months FREE. Your 1st One Month Free credit will be applied to your active account on the 7th month, and your 2nd One Month Free credit will be applied to your 13th month after installation",
  couponCode: "MOVERS2MF",
  monthsFree: 2,
  referral: false,
  moveIn: true,
} as Campaign;

const newReferralCampaign = (referralCode: string): Campaign => ({
  id: "REFERRAL",
  name: "Referral Campaign",
  rsp: "Pineapple",
  startDate: dayjs().toISOString(),
  endDate: dayjs().add(1, "year").toISOString(),
  terms:
    "One Month Free credit will be applied to your account on the 7th month after installation.",
  couponCode: referralCode,
  monthsFree: 1,
  referral: true,
  moveIn: false,
});

const newSalesReferralCampaign = (referralCode: string): Campaign => ({
  id: "SALES_REFERRAL",
  name: "Referral Campaign",
  rsp: "Pineapple",
  startDate: dayjs().toISOString(),
  endDate: dayjs().add(1, "year").toISOString(),
  terms:
    "One Month Free credit will be applied to your account on the 7th month after installation.",
  couponCode: referralCode,
  monthsFree: 1,
  referral: true,
  moveIn: false,
});

const isSpecialCampaign = (state: CampaignState) => state.moveIn || state.referral;

export const fetchCampaignAsync = createAsyncThunk(
  "campaign/fetchCampaign",
  async (buildingCode: string, thunkAPI) => {
    const response = await fetchCampaign(buildingCode);
    if (response.data.error) {
      if (response.data.status === "resource not found") {
        return initialState.value;
      }
      return thunkAPI.rejectWithValue(response.data);
    }
    return { referral: false, moveIn: false, ...response.data };
  },
);

export const fetchReferralCampaign = createAsyncThunk(
  "campaign/fetchReferralCampaign",
  async (referralCode: string, thunkAPI) => {
    const response = await CheckCoupon(referralCode, [
      "dummy-plan-id-that-we-have-to-send-to-satisfy-api",
    ]);
    if (
      response.status !== 202
      || (response.data.coupon_type !== CouponType.ReferralCode
        && response.data.coupon_type !== CouponType.SalesCommission)
    ) {
      return thunkAPI.rejectWithValue(response.data);
    }

    if (response.data.coupon_type === CouponType.SalesCommission) {
      return newSalesReferralCampaign(referralCode);
    }
    return newReferralCampaign(referralCode);
  },
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaign: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    setMoveInCampaign: (state) => {
      state.value = moveInCampaign;
      state.moveIn = true;
    },
    setTwoMonthsMoveInCampaign: (state) => {
      state.value = twoMonthsMoveInCampaign;
      state.moveIn = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCampaignAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!isSpecialCampaign(state)) {
          state.value = action.payload;
        }
      })
      .addCase(fetchCampaignAsync.rejected, (state) => {
        state.status = "failed";
        if (!isSpecialCampaign(state)) state.value = initialState.value;
      })
      .addCase(fetchReferralCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReferralCampaign.fulfilled, (state, action) => {
        state.status = "idle";
        state.referral = true;
        state.value = action.payload;
      })
      .addCase(fetchReferralCampaign.rejected, (state) => {
        state.status = "failed";
        if (!isSpecialCampaign(state)) state.value = initialState.value;
      });
  },
});

export const { setCampaign, setMoveInCampaign, setTwoMonthsMoveInCampaign } = campaignSlice.actions;

export const selectCampaign = (state: RootState) => state.campaign.value;
export default campaignSlice.reducer;
