import httpClient from '../../http/client';
import { backendURL } from '../../lib/config';
import { Plan } from '../../models/plans';

export function fetchPlans(buildingCode: string) {
  return httpClient.get<Plan[]>(
    new URL(
      `/api/v1/public/plans?building-code=${buildingCode}`,
      backendURL,
    ).toString(),
  );
}
