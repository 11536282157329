import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import PineappleModal from './PineappleModal';
import { Coupon, CouponType } from '../features/coupon/couponAPI';
import {
  Discount,
  discountWording,
  discountFromCoupon,
} from '../features/discount/discountSlice';

interface TimeOutErroModalProps {
  onSubmit: CallableFunction
  altText?: string
}

const TimeOutErroModal = ({
  onSubmit,
  altText,
}: TimeOutErroModalProps) => (
  <PineappleModal
    handleClose={() => { onSubmit(); }}
    show
    footer={(
      <Modal.Footer className="d-flex justify-content-center pt-0">
        <Button
          id="check_address_modal_success"
          variant="primary"
          size="lg"
          onClick={() => { onSubmit(); }}
        >
          <span>Select new time</span>
        </Button>
      </Modal.Footer>
        )}
  >

    <h1 className="text-center text-primary fw-bold w-100">
      {altText || "Reservation timed out"}
    </h1>

    <Alert
      variant="danger"
      className="my-1 w-100 text-center px-2 py-1 border-0"
    >
      We&apos;re sorry, but the date and time you&apos;ve selected are no longer available. Please choose another date and time.
    </Alert>
  </PineappleModal>
);

export default TimeOutErroModal;
