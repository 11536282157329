import axios from 'axios';
import { RollbarClient } from '../rollbar';

import { store } from '../app/store';

const Client = axios.create();

// Intercept all errors and send to rollbar
Client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    const state = store.getState();
    // Don't send 404 to Rollbar
    if (response.status !== 404) {
      Object.keys(state).forEach(
        (value) => console.log(value, (state as any)[value]),
      );
      RollbarClient.error(error, response.data);
    }
    return response;
  },
);

export default Client;
