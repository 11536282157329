import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Address } from "@pineapple/address-search";
import { RootState } from "../../app/store";
import { sendSignUp } from "./signupAPI";
import { ArrivyBooking, ArrivyTemplateExtraFields } from "../../models/arrivy";
import { Plan } from "../../models/plans";
import { Router } from "../../models/routers";
import { Coupon, CouponType } from "../coupon/couponAPI";

export interface SignUP {
  arrivy: ArrivyBooking;
  arrivyExtraFields: {[key: string]: ArrivyTemplateExtraFields | undefined};
  cb_token: string;
  coupon: Coupon | undefined;
  router: Router;
  addOns: Array<string>;
  plan: Plan;
  formattedAddress: string;
  mdu: boolean;
}

export interface SignUPState {
  value: SignUP;
  status: "idle" | "loading" | "failed";
}

const initialState: SignUPState = {
  value: {
    arrivyExtraFields: {},
    coupon: undefined,
    formattedAddress: "",
    plan: {} as Plan,
    router: {} as Router,
    addOns: [] as Array<string>,
    arrivy: {
      template_extra_fields: [] as Array<ArrivyTemplateExtraFields>,
    } as ArrivyBooking,
    cb_token: "",
    mdu: false,
  },
  status: "idle",
};

export const sendSignUpAsync = createAsyncThunk(
  "signup/sendSignup",
  async (signup: SignUP) => {
    const response = await sendSignUp(signup);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFormattedAddressFromUrl: (state, action: PayloadAction<Address>) => {
      const addr = action.payload;
      if (addr.unit && addr.unit !== undefined && addr.unit !== "0") {
        state.value.arrivy.customer_address_line_1 = `${addr.unit}`;
      } else {
        state.value.arrivy.customer_address_line_1 = "";
      }

      state.value.arrivy.customer_address_line_2 = `${addr.street_number} ${addr.street}`;
      state.value.arrivy.customer_city = addr.suburb;
      state.value.arrivy.customer_zipcode = addr.postcode;
      state.value.arrivy.customer_state = addr.state;
      state.value.arrivy.customer_country = "Australia";
      state.value.formattedAddress = action.payload.formatted_address;
      state.value.mdu = addr.mdu;
      state.value.arrivy.title = addr.unit && addr.unit !== "0"
        ? `PA.${addr.uniqueCode}.${addr.unit}`
        : `PA.${addr.uniqueCode}`;
    },
    setFormattedAddress: (state, action: PayloadAction<Address>) => {
      const addr = action.payload;

      if (addr.unit && addr.unit !== undefined && addr.unit !== "0") {
        state.value.arrivy.customer_address_line_1 = `${addr.unit}`;
      } else {
        state.value.arrivy.customer_address_line_1 = "";
      }

      state.value.arrivy.customer_address_line_2 = `${addr.street_number} ${addr.street}`;
      state.value.arrivy.customer_city = addr.suburb;
      state.value.arrivy.customer_zipcode = addr.postcode;
      state.value.arrivy.customer_state = addr.state;
      state.value.formattedAddress = action.payload.formatted_address;
      state.value.arrivy.title = addr.unit && addr.unit !== "0"
        ? `PA.${addr.uniqueCode}.${addr.unit}`
        : `PA.${addr.uniqueCode}`;
    },
    setAddressUniqueCodeAndUnit: (state, action: PayloadAction<any>) => {
      let unit = "";
      if (action?.payload.unit && action.payload?.unit !== undefined) {
        unit = action.payload?.unit;
        state.value.arrivy.customer_address_line_1 = unit;
      }
      state.value.arrivy.title = unit !== ""
        ? `PA.${action.payload?.uniqueCode}.${action.payload.unit}`
        : `PA.${action.payload.uniqueCode}`;
      state.value.arrivyExtraFields["Building Code"] = {
        type: "TEXT",
        name: "Building Code",
        value:
          unit !== ""
            ? `${action.payload?.uniqueCode}.${action.payload.unit}`
            : `${action.payload.uniqueCode}`,
      } as ArrivyTemplateExtraFields;
    },
    setMarketingTerms: (state, action: PayloadAction<any>) => {
      state.value.arrivyExtraFields["I Agree to the Terms and Conditions"] = {
        type: "TEXT",
        name: "I Agree to the Terms and Conditions",
        value: action.payload.termsandcons,
      } as ArrivyTemplateExtraFields;
      state.value.arrivyExtraFields["Marketing Emails"] = {
        type: "TEXT",
        name: "Marketing Emails",
        value: action.payload.marketing,
      } as ArrivyTemplateExtraFields;
    },
    setPlan: (state, action: PayloadAction<Plan>) => {
      state.value.plan = action.payload;
      state.value.arrivyExtraFields["Contract Term"] = {
        type: "TEXT",
        name: "Contract Term",
        value: "Month-Month",
      } as ArrivyTemplateExtraFields;
      state.value.arrivyExtraFields.RSP = {
        type: "TEXT",
        name: "RSP",
        value: "Pineapple Net",
      } as ArrivyTemplateExtraFields;
      state.value.arrivyExtraFields["Service Type"] = {
        type: "TEXT",
        name: "Service Type",
        value: "Residential",
      } as ArrivyTemplateExtraFields;
      state.value.arrivyExtraFields.Source = {
        type: "TEXT",
        name: "Source",
        value: "Website",
      } as ArrivyTemplateExtraFields;
      state.value.arrivyExtraFields.Speed = {
        type: "TEXT",
        name: "Speed",
        value: action.payload.speed.toString(),
      } as ArrivyTemplateExtraFields;
    },
    setContacts: (state, action: PayloadAction<any>) => {
      state.value.arrivy.customer_first_name = action.payload.firstName;
      state.value.arrivy.customer_last_name = action.payload.lastName;
      state.value.arrivy.customer_email = action.payload.email;
      // arrivy only able to send sms when the number is international
      state.value.arrivy.customer_mobile_number = action.payload.phone.substring(0, 3) !== "+61"
        ? `+61${action.payload.phone.substring(1)}`
        : action.payload.phone;
      state.value.arrivyExtraFields["How Did You Hear About Us?"] = {
        type: "TEXT",
        name: "How Did You Hear About Us?",
        value: action.payload.howDidYouHearAboutUs,
      } as ArrivyTemplateExtraFields;
    },
    setCoupon: (state, action: PayloadAction<any>) => {
      state.value.coupon = {} as Coupon;
      state.value.coupon.dollar_amount = action.payload.dollar_amount / 100;
      state.value.coupon.percentage = action.payload.percentage;
      state.value.coupon.coupon_id = action.payload.coupon_id;
      state.value.coupon.period = action.payload.period;
      state.value.coupon.period_unit = action.payload.period_unit;
      state.value.coupon.coupon_type = action.payload.coupon_type;
      state.value.coupon.plan_ids = action.payload.plan_ids;
      state.value.arrivyExtraFields["Promo Code"] = {
        type: "TEXT",
        name: "Promo Code",
        value: action.payload.coupon_id,
      } as ArrivyTemplateExtraFields;
      if (
        action.payload.coupon_type === CouponType.ReferralCode
        || action.payload.coupon_type === CouponType.SalesCommission
      ) {
        state.value.arrivyExtraFields["Referral Code"] = {
          type: "TEXT",
          name: "Referral Code",
          value: action.payload.coupon_id,
        } as ArrivyTemplateExtraFields;
      }
    },
    setRouter: (state, action: PayloadAction<Router>) => {
      state.value.router = action.payload;

      state.value.arrivyExtraFields.Router = {
        type: "TEXT",
        name: "Router",
        value: action.payload.mode,
      } as ArrivyTemplateExtraFields;
    },
    setMonthly: (state, action: PayloadAction<number>) => {
      state.value.arrivyExtraFields["Monthly Cost"] = {
        type: "TEXT",
        name: "Monthly Cost",
        value: `${action.payload}`,
      } as ArrivyTemplateExtraFields;
    },
    addAddOn: (state, action: PayloadAction<string>) => {
      // Only add the add on if it doesn't already exist in the state
      if (!state.value.addOns.includes(action.payload)) {
        state.value.addOns = [...state.value.addOns, action.payload];
        state.value.addOns.forEach((addOn) => {
          if (addOn === "new-development-fee") {
            state.value.arrivyExtraFields["New Activation Charge"] = {
              type: "TEXT",
              name: "New Activation Charge",
              value: "300",
            } as ArrivyTemplateExtraFields;
          }
          if (addOn === "static-ip") {
            state.value.arrivyExtraFields["Add-On"] = {
              type: "TEXT",
              name: "Add-On",
              value: "Static IP",
            } as ArrivyTemplateExtraFields;
          }
        });
      }
    },
    removeAddOn: (state, action: PayloadAction<string>) => {
      // Only add the add on if it doesn't already exist in the state
      if (state.value.addOns.includes(action.payload)) {
        state.value.addOns.forEach((addOn) => {
          if (addOn === "static-ip") {
            state.value.arrivyExtraFields["Add-On"] = undefined;
          }
        });
        state.value.addOns = [...state.value.addOns.filter((addOn) => addOn !== action.payload)];
        // TODO add arrivy stuff here
      }
    },
    setCbToken: (state, action: PayloadAction<any>) => {
      state.value.cb_token = action.payload.cb_token;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(sendSignUpAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendSignUpAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      })
      .addCase(sendSignUpAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setFormattedAddressFromUrl,
  setFormattedAddress,
  setAddressUniqueCodeAndUnit,
  setPlan,
  setMarketingTerms,
  setContacts,
  setRouter,
  setMonthly,
  addAddOn,
  removeAddOn,
  setCbToken,
  setCoupon,
} = signupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSignUp = (state: RootState) => state.signup.value;
export default signupSlice.reducer;
