import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './ToggleSwitch.css';

export interface ToggleSwitchProps {
  text: string
  textBold: string
  options: string[]
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

const ToggleSwitch = ({
  text, textBold, options, onChange, checked,
}: ToggleSwitchProps) => (
  <Container>
    <Row style={{
      display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center",
    }}
    >
      <Col xs lg={8} md={8}>
        <h3
          className="  text-center"
          color="black"
          style={{ fontWeight: "normal" }}
        >
          {text}
          {' '}
          <span color="black" style={{ fontWeight: "bold" }}>{textBold}</span>
        </h3>

      </Col>
      <Col xs md={4} lg={2}>
        <Form.Label
          className="toggle"
        >
          <input type="checkbox" onChange={onChange} checked={!!checked} />
          <span className="slider" />
          <span className="labels" data-on={options[0]} data-off={options[1]} />
        </Form.Label>
      </Col>
    </Row>
  </Container>
);

export default ToggleSwitch;
