import httpClient from '../../http/client';
import { backendURL } from '../../lib/config';

// {
//   "id": "0188ce62-6f8f-76ce-b551-41105b812446",
//   "name": "Test",
//   "rsp": "Pineapple",
//   "startDate": "2023-06-18T00:00:00+10:00",
//   "endDate": "2023-07-19T23:59:59+10:00",
//   "monthsFree": 1,
//   "terms": ""
// }
type CampaignResponse = {
  id: string,
  name: string,
  rsp: string,
  startDate: string,
  endDate: string,
  terms: string,
  monthsFree?: number,
  status?: string,
  error?: string
}
export const fetchCampaign = (
  buildingCode: string,
) => httpClient.get<CampaignResponse>(
  new URL(
    `/api/v1/public/building/${buildingCode}/campaign`,
    backendURL,
  ).toString(),
  {
    headers: {
      Accept: 'application/json',
    },
  },
);
