import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface PineappleModalProps {
  show: boolean;
  handleClose: any;
  children: JSX.Element | JSX.Element[];
  footer: JSX.Element | JSX.Element[];
  size?: 'sm' | 'lg' | 'xl' | undefined;
}

const PineappleModal = ({
  show, handleClose, children, footer, size,
} : PineappleModalProps) => (
  <Modal
    show={show}
    onHide={handleClose}
    centered
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
  >
    <Modal.Header className="pb-0 pt-3" closeButton />
    <Modal.Body className="py-1">
      {children}
    </Modal.Body>
    {footer}
  </Modal>

);

export default PineappleModal;
