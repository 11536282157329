import dayjs from "dayjs";
import { ulid } from "ulid";

import httpClient from "../../http/client";
import { backendURL } from "../../lib/config";
import { ExternalId } from "./reservationsSlice";

export interface Reservation {
  reservation_id: string;
  external_id: string;
  expires_at: string;
}

const byReservationId: Record<string, Reservation> = {};
const byExternalId: Record<string, Reservation> = {};

// TODO: Wire this up to the CREATE reservations API later on.
export const create = (externalId: ExternalId) => httpClient.post<Reservation>(`${backendURL}/api/v1/pineapple/reservations`, {
  ...externalId,
});
