import React, { Component } from 'react';
import { Badge, Container, Stack } from 'react-bootstrap';
import TagManager from 'react-gtm-module';
import { UseFormReturn } from 'react-hook-form';
import CheckAddress from './CheckAddress';
import PlanBuilder from './PlanBuilder';
import ContactDetails from './ContactDetails';
import Picker from './Picker';
import Fin from './Fin';
import AddressDetails from './AddressDetails';
import CreditCardInfo from './CreditCardInfo';
import { FormValues } from '../lib/errors';

type Props = {
  register: any
  formState: any
  getValues: any
  handleSubmit: any
  addrForm: UseFormReturn<FormValues, any>;
}

type State = {
  page: number
}

document.body.style.background = '#fafafa';

type FormStepProps = {
  number: number;
  title: Array<string>;
  active: boolean;
}

const FormStep = (props: FormStepProps) => {
  const {
    number, title, active,
  } = props;
  const color = active ? 'primary' : '';
  return (
    <div className={`text-center ${active ? 'active' : ''}`}>
      <Badge
        className="d-inline-block"
        bg={color}
      >
        {number}
      </Badge>
      <span className="d-none d-md-block">
        {title[0]}
      </span>
      <span className="d-block d-md-none">
        {title[1]}
      </span>
    </div>
  );
};

type FormProgressProps = {
  offset: number;
  activeStep: number;
}

const FormProgress = (props: FormProgressProps) => {
  const { activeStep, offset } = props;
  const steps = [
    ['Build your plan', 'Build'],
    ['Contact info', 'Contact'],
    ['Connection details', 'Connection'],
    ['Installation', 'Installation'],
    ['Payment', 'Payment'],
    ['Confirm order', 'Confirm'],
  ];

  // Step numbering starts after the offset
  if (activeStep <= offset) {
    return null;
  }

  return (
    <Container className="pineapple-form-progress px-2 px-md-0">
      <Stack
        direction="horizontal"
        className="d-flex justify-content-between position-relative"
      >
        {steps
          .map((step, idx) => (
            <FormStep
              number={idx + 1}
              title={step}
              key={`${idx + 1}`}
              active={activeStep === (idx + offset + 1)}
            />
          ))}
        <hr />
      </Stack>
    </Container>
  );
};

const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  });
};

const googleAnalyticsStepLabels = [
  'Check Address',
  'Build Plan',
  'Connection Details',
  'Installation',
  'Payment',
  'Confirm Order',
  'Paid',
];

class Form extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.twoPagesBack = this.twoPagesBack.bind(this);
    this.state = {
      page: 1,
    };
  }

  nextPage() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
    scrollToTop();
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
    scrollToTop();
  }

  twoPagesBack() {
    this.setState((prevState) => ({ page: prevState.page - 2 }));
    scrollToTop();
  }

  render() {
    const {
      addrForm, register, handleSubmit, formState, getValues,
    } = this.props;
    const {
      page,
    } = this.state;

    const tagManagerArgs = {
      dataLayer: {
        page: googleAnalyticsStepLabels[page - 1],
        value: page - 1, // Further through form means more value
      },
      dataLayerName: 'dataLayer',
    };

    TagManager.dataLayer(tagManagerArgs);

    return (
      <>
        <FormProgress offset={1} activeStep={page} />
        {page === 1 && (
        <CheckAddress
          onSubmit={this.nextPage}
        />
        )}
        {page === 2 && (
        <PlanBuilder
          onSubmit={this.nextPage}
          previousStep={this.previousPage}
        />
        )}
        {page === 3 && (
        <ContactDetails
          handleSubmit={handleSubmit}
          register={register}
          formState={formState}
          getValues={getValues}
          onSubmit={this.nextPage}
          previousStep={this.previousPage}
        />
        )}
        {page === 4 && (
        <AddressDetails
          handleSubmit={addrForm.handleSubmit}
          register={addrForm.register}
          formState={addrForm.formState}
          onSubmit={this.nextPage}
          previousStep={this.previousPage}
        />
        )}
        {page === 5 && <Picker onSubmit={this.nextPage} previousStep={this.previousPage} />}
        {page === 6 && (
        <CreditCardInfo
          onSubmit={this.nextPage}
          previousStep={this.previousPage}
        />
        )}
        {page === 7 && <Fin previousStep={this.twoPagesBack} />}
      </>
    );
  }
}

export default Form;
