import httpClient from "../../http/client";
import { arrivyBookingID, backendURL } from "../../lib/config";

export type Booking = {
  updated: string;
  is_disabled: boolean;
  start_datetime: string;
  end_datetime: string;
  end_datetime_original_iso_str: string;
  id: number;
  booking_id: number;
  url_safe_id: string;
  start_datetime_original_iso_str: string;
};

type AddrResponse = {
  booking_slots: Array<Booking>;
};

function formatToRFC3339WithOffset(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const offset = date.getTimezoneOffset();
  const offsetSign = offset > 0 ? '%2D' : '%2B';
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export function fetchBookings(state: string, date: Date) {
  // First day of the current month (start of the month at 00:00:00)
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
  const fr = formatToRFC3339WithOffset(firstDay);

  // First day of the month three months later (start of the month at 00:00:00)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 3, 1, 0, 0, 0);
  const to = formatToRFC3339WithOffset(lastDay);

  return httpClient.get<AddrResponse>(
    new URL(`/api/v1/pineapple/bookings/${state}?from=${fr}&to=${to}`, backendURL).toString(),
    {
      headers: {
        Accept: "application/json",
      },
    },
  );
}

export function fetchBooking(state: string, date: Date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
  const fr = formatToRFC3339WithOffset(firstDay);

  // End of the day (23:00:00) in local timezone
  const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23);
  const to = formatToRFC3339WithOffset(lastDay);

  return httpClient.get<AddrResponse>(
    new URL(`/api/v1/pineapple/bookings/${state}?from=${fr}&to=${to}`, backendURL).toString(),
    {
      headers: {
        Accept: "application/json",
      },
    },
  );
}
