import React, {
  PropsWithChildren, useRef, useState, useEffect, Children, useLayoutEffect,
} from 'react';

import { Stack, StackProps } from 'react-bootstrap';

import ChevronButton from './ChevronButton';

const leftOverlayGradient = `linear-gradient(
90deg,
rgba(250,250,250,1) 0%,
rgba(250,250,250,0) 10%,
rgba(250,250,250,0) 100%)`;

const rightOverlayGradient = `linear-gradient(
90deg,
rgba(250,250,250,0) 0%,
rgba(250,250,250,0) 90%,
rgba(250,250,250,1) 100%)`;

const leftRightOverlayGradient = `linear-gradient(
90deg,
rgba(250,250,250,1) 0%,
rgba(250,250,250,0) 10%,
rgba(250,250,250,0) 90%,
rgba(250,250,250,1) 100%)`;

interface ScrollStackProps extends StackProps {
  initialPosition?: 'start' | 'center' | 'end' | 'auto' | 'offset-1';
  mostPopular: boolean;
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const ScrollStack = (
  {
    children, mostPopular, initialPosition = 'center', gap = 0, ...props
  }: PropsWithChildren<ScrollStackProps>,
) => {
  if (children == null) return null;
  const ref = useRef<HTMLElement>();
  const { current } = ref;
  const [showLeft, setShowLeft] = useState<boolean>(false);
  const [showRight, setShowRight] = useState<boolean>(false);
  const [lastScroll, setLastScroll] = useState<Date>();
  const scrollPadding = Number(gap.valueOf()) * 16 * 2;

  // Center if not scrollable
  if (current === undefined || (current.scrollWidth <= current.offsetWidth)) {
    props.className += 'justify-content-center';
  }

  const gradient = () => {
    if (showLeft && showRight) {
      return leftRightOverlayGradient;
    }

    if (showLeft) {
      return "#fff";
    }

    if (showRight) {
      return "#fff";
    }

    return 'transparent';
  };

  const opacity = () => {
    if (showLeft && showRight) {
      return "0.0";
    }

    if (showLeft) {
      return "0.0";
    }

    if (showRight) {
      return "0.0";
    }

    return 0.0;
  };

  useEffect(() => {
    // Skip if div not scrollable
    if (
      current === undefined || (current.scrollWidth <= current.offsetWidth)
    ) return;

    if (lastScroll === undefined) {
      // Execute in timeout with no delay so setting scroll position is sent to
      // queue and processed last by the event loop. Hack to cause scroll
      // position to be forced last.
      setTimeout(() => {
        // On initial load scroll to the configured initialPosition.
        switch (initialPosition) {
          // scroll to start
          case 'start':
            current.scrollLeft = 0;
            break;
          // scroll to end
          case 'end':
            current.scrollLeft = current.offsetWidth;
            break;
          // offset scroll 1 child from start
          case 'offset-1':
            if (
              (current.scrollWidth / Children.count(children))
              > (current.offsetWidth / 2)
            ) {
              current.scrollLeft = (
                (current.scrollWidth / Children.count(children)) / 2
              ) + scrollPadding;
            } else {
              current.scrollLeft = scrollPadding;
            }
            break;
          default: {
            const center = (
              current.scrollWidth - current.offsetWidth
            ) / 2;
            // For odd number of children scroll to center + half the average
            // width of children.
            if (Children.count(children) % 2 === 0) {
              current.scrollLeft = center + (
                (current.scrollWidth / Children.count(children)) / 2
              ) + scrollPadding;
            // Otherwise just scroll to center
            } else {
              current.scrollLeft = center;
            }
            break;
          }
        }
        setLastScroll(new Date());
      });
    }
  }, [children]);

  useEffect(() => {
    // Skip if div not scrollable
    if (
      current === undefined || (current.scrollWidth <= current.offsetWidth)
    ) return;

    if (current && current.scrollLeft > 0) {
      setShowLeft(true);
    } else {
      setShowLeft(false);
    }

    if (
      current && current.scrollLeft < (
        current.scrollWidth - current.offsetWidth
      ) && (current.scrollWidth !== current.offsetWidth)
    ) {
      setShowRight(true);
    } else {
      setShowRight(false);
    }
  });

  const moveScrollPosition = (direction: string) => () => {
    // Skip if div not scrollable
    if (
      current === undefined || (current.scrollWidth <= current.offsetWidth)
    ) return;

    if (direction === 'left') {
      current.scrollLeft -= (
        current.scrollWidth / Children.count(children)
      ) + scrollPadding;
    } else {
      current.scrollLeft += (
        current.scrollWidth / Children.count(children)
      ) + scrollPadding;
    }
    setLastScroll(new Date());
  };

  const handleScroll = () => {
    setLastScroll(new Date());
  };

  const left = (
    <ChevronButton
      style={{
        pointerEvents: 'auto',
        position: 'absolute',
        left: '1rem',
        top: '50%',
        zIndex: 2,
      }}
      onClick={moveScrollPosition('left')}
      size={60}
      direction="left"
    />
  );

  const right = (
    <ChevronButton
      style={{
        pointerEvents: 'auto',
        position: 'absolute',
        right: '1rem',
        top: '50%',
        zIndex: 2,
      }}
      onClick={moveScrollPosition('right')}
      size={60}
      direction="right"
    />
  );

  const [width, height] = useWindowSize();

  useEffect(() => {
    moveScrollPosition(initialPosition);
    setLastScroll(new Date());
    setShowLeft(false);
    setShowRight(false);
  }, [mostPopular, width, height]);

  return (
    <div style={{
      position: 'relative',
      pointerEvents: 'auto',
      zIndex: 2,
    }}
    >
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        background: gradient(),
        opacity: opacity(),
        zIndex: 2,
      }}
      />
      {(showLeft) && left}
      {(showRight) && right}
      <Stack
        {...props}
        gap={gap}
        ref={ref}
        style={{ position: 'relative', scrollBehavior: 'smooth' }}
        onScroll={handleScroll}
      >

        { children }
      </Stack>
    </div>
  );
};

export default ScrollStack;
