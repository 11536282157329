import React, {
  useState, useCallback, useEffect, SetStateAction,
} from 'react';
import {
  Button, Container, Stack, Row, Col, Modal,
} from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import dayjs from 'dayjs';
import AddressSearch from '@pineapple/address-search';
import type { Address } from '@pineapple/address-search';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectAddress,
  setAddress,
  emptyAddress,
} from '../features/address/addressSlice';
import {
  Campaign,
  selectCampaign,
  fetchCampaignAsync,
} from '../features/campaign/campaignSlice';
import {
  setDiscount,
  newCampaignDiscount,
} from '../features/discount/discountSlice';
import {
  setFormattedAddress,
} from '../features/signup/signupSlice';
import ExpressInterest from './ExpressInterest';
import PineappleModal from './PineappleModal';
import PineappleAlert from './PineappleAlert';
import { humanizeNumber } from '../lib/utils';
import MostPopularPlansSwitch from './MostPopularPlansSwitch';

export interface CheckAddressProps {
  onSubmit: any;
}

interface CampaignOfferProps {
  campaign: Campaign;
}

const CampaignOffer = ({ campaign }: CampaignOfferProps) => {
  const { monthsFree: n } = campaign;
  const promotionCopy = (n !== undefined)
    ? `${humanizeNumber(n)} ${n > 1 ? 'months' : 'month'} FREE`
    : 'Promotional Pricing Applies';

  const specialCampaignCopy = (
    <>
      Switch to Pineapple Net today to get
      {' '}
      <strong className="text-capitalize">{ promotionCopy }</strong>
    </>
  );

  const normalCampaignCopy = (
    <>
      Special building offer &mdash;
      {' '}
      <strong className="text-capitalize">{ promotionCopy }</strong>
      <br />
      Hurry, offer ends
      {' '}
      { dayjs(campaign.endDate).format('DD/MM/YYYY') }
    </>
  );

  return (
    <PineappleAlert className="d-block">
      { (campaign.moveIn || campaign.referral)
        ? specialCampaignCopy : normalCampaignCopy }
    </PineappleAlert>
  );
};

interface ModalCasesProps {
  LIT: boolean;
  commercial: boolean;
  greenfield: boolean;
  mixed: boolean;
  campaign: Campaign;
  commercialChosen: boolean;
  setCommercialChosen: any;
}

const ModalCases = ({
  LIT, commercial, greenfield, campaign, mixed, commercialChosen, setCommercialChosen,
}: ModalCasesProps) => {
  let modalBody = null;

  if (greenfield) {
    return (
      <>
        <h4 className="text-primary text-center">
          Welcome to your new apartment. Your super fast internet is ready to be
          connected.
        </h4>
        <p className="lead text-center">
          Your building is connected to DGtek Fibre Network providing you with
          access to internet speeds up to 1GB!
        </p>
      </>
    );
  }
  if (LIT === true && mixed === true) {
    modalBody = (
      <>
        {commercialChosen

          ? (
            <>
              <p className="lead text-center">
                To connect to the DGTek Fibre Network please contact FG Telecom on 1300 318 709.
              </p>
              <div style={{
                display: "table-cell",
                textAlign: "center",
                verticalAlign: "middle",
                paddingTop: "40px",
                paddingBottom: "80px",
              }}
              >
                <a href="https://fgtelecom.com.au" target="_blank" rel="noopener noreferrer">
                  {' '}
                  <img style={{ width: "40%" }} alt="FG Telecom" src="fgtelecom.png" />
                </a>
              </div>
            </>
          )
          : (
            <>
              <p className="lead text-center">
                DGtek Fibre Network is available in this building and is able to support both Residential and Business Connections.
              </p>

              <p className=" text-center">
                Please indicate whether you’d like a Residential or Business Connection.
              </p>
            </>
          )}
        <br />
        <MostPopularPlansSwitch options={["Residential", "Business"]} onChange={() => setCommercialChosen(!commercialChosen)} checked={commercialChosen} />
        <br />
      </>
    );
  } else if (LIT === true && commercial !== true) {
    modalBody = (
      <>
        <p className="lead text-center">
          Your building is connected to one of Australia’s fastest private
          fibre optic networks providing you with access to ultra-fast
          internet with speeds up to 1GB.
        </p>
        {(campaign.id
          ? <CampaignOffer campaign={campaign} />
          : (
            <p className="text-center">
              Connect to Pineapple Net for a refreshingly faster, more reliable,
              better value alternative to the NBN!
            </p>
          )
        )}
      </>
    );
  } else if (commercial === true) {
    modalBody = (
      <>
        <p className="lead text-center">
          DGTek Fibre Network is available in your building. However, this building has been identified as a Commercial site.
        </p>
        <p className="text-center">
          To connect to the DGTek Fibre Network please contact FG Telecom on 1300 318 709.
        </p>
      </>
    );
  } else {
    modalBody = (
      <>
        <p className="lead text-center">
          Pineapple Net is available in your building. For now the maximum
          speed available to your address is 100Mpbs, but we&apos;re working hard
          to upgrade your building as soon as we can.
        </p>
        {(campaign.id
          ? <CampaignOffer campaign={campaign} />
          : (
            <p className="text-center">
              For a refreshingly faster, more reliable, better value
              alternative to the nbn connect with Pineapple Net.
            </p>
          )
        )}
      </>

    );
  }

  return modalBody;
};

const ModalHeader = ({ LIT, greenfield, commercial }: any) => {
  let modalHeader = '';

  if ((LIT === true || greenfield) && commercial !== true) {
    modalHeader = 'Great News!';
  } else {
    modalHeader = 'Good News!';
  }

  return (
    <h1
      className="text-center text-primary fw-bold w-100"
    >
      {modalHeader}
    </h1>
  );
};

const CheckAddress = ({ onSubmit }: CheckAddressProps) => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const campaign = useAppSelector(selectCampaign);

  const [commercialChosen, setCommercialChosen] = useState(false);

  useEffect(() => {
    // moveIn and referral campaigns take precedence
    if (campaign.id && (!campaign.moveIn && !campaign.referral)) {
      dispatch(fetchCampaignAsync(addr.uniqueCode));
    }
    if (campaign.id === undefined) {
      dispatch(fetchCampaignAsync(addr.uniqueCode));
    }
  }, [addr]);

  useEffect(() => {
    // moveIn and referral campaigns take precedence
    if (campaign.id && (!campaign.moveIn && !campaign.referral)) {
      dispatch(setDiscount(newCampaignDiscount("Special Building Offer")));
    }

    if (campaign.id === undefined) {
      dispatch(fetchCampaignAsync(addr.uniqueCode));
    }
  }, [campaign]);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);
  const [showExpressInterest, setShowExpressInterest] = useState<boolean>(false);

  const computeSuccess = (address: Address) => [
    'LIT',
    'ComingSoon',
    'BuildCommenced',
  ].includes(address.status);

  // Respond to address changing and submitted the form so as not to race set
  // state with events dispatching through the system
  useEffect(() => {
    if (addr !== emptyAddress) {
      setSuccess(computeSuccess(addr));
    }
  }, [addr, submitted]);

  const handleAddressSearchResult = useCallback((address: Address) => {
    dispatch(setFormattedAddress(address));
    dispatch(setAddress(address));
    // moveIn and referral campaigns take precedence
    if (!campaign.id && !campaign.moveIn && !campaign.referral) {
      dispatch(fetchCampaignAsync(address.uniqueCode));
    }
    setSubmitted(true);
  }, [campaign]);

  const handleClose = useCallback(() => {
    setSubmitted(false);
    setSuccess(false);
    setClosed(true);
  }, [addr]);

  const form = (
    <>
      <Fade timeout={200} in={campaign.referral}>
        <Container className={`text-center mt-5 fade-in ${campaign.referral ? '' : 'd-none'}`}>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <h1 className="display-2 fw-bold text-primary">
                <span className="text-black">Welcome to </span>
                Pineapple Net!
              </h1>
              { campaign.id === "SALES_REFERRAL"
                ? (
                  <p className="lead text-black">
                    You’re a few easy steps away from getting the fastest
                    internet around that’s more reliable and far better value
                    than the NBN! PLUS One Month FREE Internet!
                  </p>
                )
                : (
                  <p className="lead text-black">
                    Thanks to your neighbour, you’re a few easy steps away from
                    getting the fastest internet around that’s more reliable
                    and far better value than the NBN!
                  </p>
                )}
              <p className="lead fw-bold text-black">
                Now that&apos;s refreshing!
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>
      <Container style={{ flexGrow: 1 }} className="mb-md-5 d-flex align-items-center justify-content-center">
        <Row>
          <Col>
            <Stack direction="vertical" gap={1}>
              <h1 className="text-center text-primary fw-bold">
                Check your address
              </h1>
              <h4 className="text-center fw-light">
                To start, let’s find out if Pineapple Net is available at your
                address
              </h4>
              <br />
              <AddressSearch
                apiKey="AIzaSyAtqvweVLEdaXwxfQkmp5Yotm13rFoVYhc"
                onSubmit={handleAddressSearchResult}
              />
              <p className="mt-3 text-center">
                Enter the
                {' '}
                <strong>BUILDING</strong>
                {' '}
                number only - for example,
                {' '}
                <strong>
                  1 Freshwater Place,
                  Southbank
                </strong>
                .
                <br />
                (Your apartment, unit or level number is not required)
              </p>
            </Stack>
          </Col>
        </Row>
        <PineappleModal
          show={(submitted && success) || (!closed && computeSuccess(addr) && addr?.uniqueCode !== undefined && addr?.formatted_address !== undefined)}
          handleClose={handleClose}
          footer={(
            <Modal.Footer className="d-flex justify-content-center pt-0">
              {addr.commercial === true || commercialChosen ? null : (
                <Button
                  id="check_address_modal_success"
                  variant="primary"
                  size="lg"
                  onClick={() => onSubmit()}
                >
                  <span>View plans</span>
                </Button>
              )}
            </Modal.Footer>
        )}
        >
          <div className="d-flex justify-content-center mb-2">
            <svg
              width="67px"
              height="56px"
              viewBox="0 0 67 56"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="modal-tick"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  transform="translate(-668.000000, -245.000000)"
                  stroke="#F5E748"
                  strokeWidth="10"
                >
                  <g
                    transform="translate(408.000000, 209.000000)"
                  >
                    <polyline
                      points="265
                      70.2089976
                      281.765416
                      86.844
                      321.844107
                      41.844"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <ModalHeader LIT={addr.status === 'LIT'} greenfield={addr.buildingCategory === 'greenfield'} commercial={addr.commercial} />
          <ModalCases
            mixed={addr.mixed}
            LIT={addr.status === 'LIT'}
            commercial={addr.commercial}
            greenfield={addr.buildingCategory === 'greenfield'}
            campaign={campaign}
            setCommercialChosen={setCommercialChosen}
            commercialChosen={commercialChosen}
          />
        </PineappleModal>
        <PineappleModal
          show={((submitted && !success) || (!closed && !computeSuccess(addr) && addr?.uniqueCode !== undefined && addr?.formatted_address !== undefined))}
          handleClose={handleClose}
          footer={(
            <Modal.Footer className="d-flex justify-content-center pt-0">
              <Button
                id="check_address_modal_fail"
                variant="primary"
                size="lg"
                onClick={() => setShowExpressInterest(true)}
              >
                <span>Register your address</span>
              </Button>
            </Modal.Footer>
        )}
        >
          <h1
            className="text-center text-primary fw-bold w-100"
          >
            We are sorry!
          </h1>
          <p className="lead text-center">
            Sorry, unfortunately Pineapple Net is not available at your address but we hope that will change soon.
          </p>

          <p className="lead text-center">
            Please register you details so we can keep you updated as we expand our network to get you.
          </p>
        </PineappleModal>
      </Container>
    </>
  );

  if (showExpressInterest) {
    return <ExpressInterest />;
  }

  return form;
};

export default CheckAddress;
