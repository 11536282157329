import React, { useEffect } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';

const ExpressInterest = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window?.hbspt) {
        // @ts-ignore
        window?.hbspt.forms.create({
          portalId: '8498425',
          formId: '16db8c63-2191-4c81-b6e8-e3f00d0864cb',
          target: '#hubspotForm',
          region: 'na1',
        });
      }
    });
  }, []);

  return (
    <Container>
      <Row className="px-2 py-5 justify-content-center">
        <Col xs md={6} id="hubspotForm" />
      </Row>
    </Container>
  );
};

export default ExpressInterest;
