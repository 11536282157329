import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { RollbarConfig } from './rollbar';
import { store } from './app/store';
import App from './App';
import './custom.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

const tagManagerArgs = {
  gtmId: 'GTM-N6B9HGX',
  dataLayerName: 'dataLayer',
  events: {
    sendUserInfo: 'userInfo',
  },
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RollbarProvider config={RollbarConfig}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </RollbarProvider>
    </Provider>
  </React.StrictMode>,
);
