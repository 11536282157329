import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Reservation, create } from "./reservationsAPI";

export interface ReservationState {
  value: Reservation | null;
  status: "idle" | "loading" | "failed";
}

export const initialState: ReservationState = {
  value: null,
  status: "idle",
};

export interface ExternalId {
  external_id: string
  address: string
  sid: string
}

export const createReservation = createAsyncThunk(
  "reservations/createReservation",
  async (externalId: ExternalId, thunkApi): Promise<Reservation> => {
    const reservation = await create(externalId);
    if (reservation.status === 409) {
      throw thunkApi.rejectWithValue("reservation taken");
    }
    return reservation.data;
  },
);

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    releaseReservation: (state) => {
      state.value = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createReservation.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      })
      .addCase(createReservation.rejected, (state) => {
        state.status = "failed";
        state.value = null;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectReservation = (state: RootState) => state.reservations.value;
export const selectReservationState = (state: RootState) => state.reservations;

export const { releaseReservation } = reservationsSlice.actions;

export default reservationsSlice.reducer;
