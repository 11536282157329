import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

interface ChevronButtonProps extends React.HTMLProps<HTMLDivElement>{
    size: number,
    direction: 'left'|'right'
}

const ChevronButton = ({ size, direction, ...props }: ChevronButtonProps) => {
  const Icon = direction === 'right' ? ChevronRight : ChevronLeft;
  return (
    <div style={{ display: 'inline-block' }} {...props}>
      <Button
        className="shadow-sm bg-orange border-orange"
        style={{ position: 'relative', height: size, width: size }}
      >
        <Icon
          size={size / 2}
          className="position-absolute w-100 h-100 p-1 top-0 end-0"
        />
      </Button>
    </div>
  );
};

export default ChevronButton;
