import React from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import {
  TelephoneFill, Linkedin, Facebook, Instagram,
} from 'react-bootstrap-icons';

const PineappleNav = () => (
  <>
    <Navbar className="d-none d-md-block p-0" bg="warning">
      <Container fluid>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="text-black px-2">
            <TelephoneFill className="mb-1 me-1 " color="black" />
            <a style={{ textDecoration: 'none' }} href="tel:1300857501">
              1300 857 501
            </a>
          </Navbar.Text>
          <Navbar.Text className="px-1">
            <a href="https://www.facebook.com/PineappleNetAU/" target="_blank" rel="noreferrer">
              <Facebook
                className="mb-1 me-1"
                color="black"
              />
            </a>
          </Navbar.Text>
          <Navbar.Text className="px-1">
            <a style={{ textDecoration: 'none' }} href="https://www.instagram.com/pineapplenetau/" target="_blank" rel="noreferrer">
              <Instagram
                className="mb-1 me-1"
                color="black"
              />
            </a>
          </Navbar.Text>
          <Navbar.Text className="px-1">
            <a style={{ textDecoration: 'none' }} href="https://www.linkedin.com/company/pineapplenet/" target="_blank" rel="noreferrer">
              <Linkedin
                className="mb-1 me-1"
                color="black"
                href="https://www.linkedin.com/company/pineapplenet/"
              />
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Navbar className="py-1" expand="md">
      <Container fluid>
        <Navbar.Brand className="px-md-2 py-0" href="https://pineapple.net.au">
          <img
            src="logo-black.png"
            width="250"
            className="d-none d-md-inline-block align-top "
            alt="Pineapple Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="pineapple-navbar" />
        <Navbar.Collapse id="pineapple-navbar">
          <Nav className="ms-auto">
            <Nav.Link
              className="fw-bolder py-2 py-md-1 ms-md-2 text-black"
              href="https://pineapple.net.au"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className="fw-bolder py-2 py-md-1 ms-md-2 text-black"
              href="https://pineapple.net.au/#about"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              className="fw-bolder py-2 py-md-1 ms-md-2 text-black"
              href="https://pineapple.net.au/#plans"
            >
              Plans
            </Nav.Link>
            <Nav.Link
              className="fw-bolder py-2 py-md-1 ms-md-2 mb-md-0 mb-2 text-black"
              href="https://status.pineapple.net.au"
            >
              Network status
            </Nav.Link>
            <Nav
              style={{ paddingTop: '0.25rem' }}
              className="fw-bolder ms-md-2"
            >
              <Button
                className="px-4 nav1-button"
                size="sm"
                variant="outline-danger"
                href="https://pineapple.chargebeeportal.com"
              >
                Sign In
              </Button>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
);

export default PineappleNav;
